import React, { ReactNode } from 'react';
import "./index.css";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <main>{children}</main>
      <footer>&copy; 2021 - {new Date().getFullYear()} Hogcloud, Inc.</footer>
    </>
  );
};

export default Layout;
