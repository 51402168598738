import React, { useState } from "react";
import "./index.css"; // Import the CSS file

function About() {
  const [response, setResponse] = useState("");
  const [inputData, setInputData] = useState("");
  const [isChecked, setIsChecked] = useState(false); // State to track the toggle switch

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    try {
      const res = await fetch("https://your-endpoint.com/api", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: inputData }),
      });

      const result = await res.json();
      setResponse(JSON.stringify(result));
    } catch (error) {
      setResponse("Error: " + error);
    }
  };

  const handleToggle = () => {
    setIsChecked(!isChecked); // Toggle the value
  };

  return (
    <div>
        <form onSubmit={handleSubmit}>
        <textarea
            rows={5}
            cols={40}
            value={inputData}
            onChange={(e) => setInputData(e.target.value)}
            placeholder="Enter text to send with POST request"
        />
        <button type="submit">Submit</button>
        
        <div className="toggle-container">
            <label className="toggle-label">Enable Feature</label>
            <label className="toggle-switch">
            <input
                type="checkbox"
                checked={isChecked}
                onChange={handleToggle}
            />
            <span className="toggle-slider"></span>
            </label>
        </div>
        
        <textarea
            rows={5}
            cols={40}
            value={response}
            readOnly
            placeholder="Response will appear here"
        />
        </form>
        <a href="https://hogcloud.awsapps.com/start#" target="_blank">Login</a>
    </div>
  );
}

export default About;
