import React from 'react';
import { NavLink } from 'react-router-dom';
import { RiInformationLine, RiContactsLine, RiAccountBoxFill } from 'react-icons/ri';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import './index.css'
import logo_hogcloud1 from "../../assets/images/hogcloud-logo1.png";
import {RiLightbulbFlashLine} from "react-icons/ri";

const Navbar = () => {
  return (
    <nav>
      <ul>
        <li>
          <NavLink to="/" className={({isActive}) =>  isActive ? 'active2' : 'inactive'}>
            <img src={logo_hogcloud1} alt="hogcloud logo pig in cloud" className="hc_logo_small" />
          </NavLink>
        </li>
        <li>
            <NavLink to="/services" className={({ isActive }) => isActive ? "active" : ""}>
                    <FontAwesomeIcon icon={faFloppyDisk} className='active hc-nav-icon-spacing' />
                    Services
            </NavLink>
        </li>
        <li>
          <NavLink to="/portfolio" className={({ isActive }) => isActive ? "active" : ""} >
            <RiAccountBoxFill className='active hc-nav-icon-spacing' />
            Portfolio
          </NavLink>
        </li>   
        <li>
          <NavLink to="/learn" className={({ isActive }) => isActive ? "active" : ""}>
            <RiLightbulbFlashLine className='active hc-nav-icon-spacing'/>
            Learn
          </NavLink>
        </li>               
        <li>
          <NavLink to="/about" className={({ isActive }) => isActive ? "active" : ""}>
            <RiInformationLine className='active hc-nav-icon-spacing'/>
            About
          </NavLink>
        </li>        
        <li>
          <NavLink to="/contact" className={({ isActive }) => isActive ? "active" : ""}>
            <RiContactsLine className='active hc-nav-icon-spacing'/>
            Contact
          </NavLink>
        </li>
        <li></li>
      </ul>
      
    </nav>
  );
};

export default Navbar;
