
import './App.css';
import React from 'react';

import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Navbar from './components/Navbar'
import Layout from './components/Layout';
import Services from './components/Services'
import Contact from './components/Contact';


/* import Contact from './components/Contact';
import Portfolio from './components/Portfolio';
import Devices from 'components/Devices';
import Learn from './components/Learn';
 */



function App() {
  let linkToPage = "/about";
  const buttonText = "Learn more";
  return (
    <div>
    <Navbar />
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Layout>
  </div>
  );
}

export default App;
